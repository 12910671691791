import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../config/api';
import { getAuthData, setAuthData } from '../../config/cookie';
import { setAuthDetailsByLocalStorage } from './authSlice';

export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');
export const clearProfileState = createAction('CLEAR_PROFILE_STATE');
export const setMobileNo = createAction('SET_MOBILE_NO');
export const setEmailId = createAction('SET_EMAIL_ID');
export const resetPreDataOfOcrInProfile = createAction(
  'RESET_PRE_DATA_OF_OCR_IN_PROFILE'
);
export const clearPennyDropVerificationInProfile = createAction(
  'CLEAR_PENNY_DROP_VERIFICATION_IN_PROFILE'
);
export const clearBankDetails = createAction('CLEAR_BANK_DETAILS');

export const clearReferRequestState = createAction('CLEAR_REFER_REQUEST_STATE');

export const clearRelationShipManagerState = createAction(
  'CLEAR_RELASHIONSHIP_MANAGER_STATE'
);

export const clearRaiseComplaintState = createAction(
  'CLEAR_RAISE_COMPLAINT_STATE'
);

export const clearReInvestmentDetails = createAction(
  'CLEAR_REINVESTMENT_DETAILS'
);

export const clearNomineeDetailsForProfile = createAction(
  'CLEAR_NOMINEE_DETAILS_FOR_PROFILE'
);

export const sendEmailVerificationOtp = createAsyncThunk(
  'profile/send_email_verification_otp',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/users/assisted/profile/sendVerifyEmailUpdateOtp',
        credentials
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyAndUpdateEmail = createAsyncThunk(
  'profile/verify_and_upadate_email',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/users/assisted/profile/sendVerifyEmailUpdateOtp',
        credentials
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue({
          error: true,
          errorMessage: 'Error occured while updating Email ID.',
          description: 'Something Went Wrong',
          statusCode: 500
        });
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendMobileNoVerificationOtp = createAsyncThunk(
  'profile/send_mobile_verification_otp',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/users/assisted/profile/sendVerifyMobileUpdateOtp',
        credentials
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyAndUpdateMobileNo = createAsyncThunk(
  'profile/verify_and_upadate_mobile',
  async (credentials, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        '/users/assisted/profile/sendVerifyMobileUpdateOtp',
        credentials
      );

      if (response.status === 200) {
        const authdata = getAuthData();
        dispatch(
          setAuthDetailsByLocalStorage({
            ...authdata,
            mobileNo: credentials?.mobileNo
          })
        );
        return response.data;
      } else {
        return rejectWithValue({
          error: true,
          errorMessage: 'Error occured while updating mobile number.',
          description: 'Something Went Wrong',
          statusCode: 500
        });
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const reSendEmailVerificationOtp = createAsyncThunk(
  'profile/resend_email_verification_otp',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/users/assisted/profile/sendVerifyEmailUpdateOtp',
        credentials
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const reSendMobileNoVerificationOtp = createAsyncThunk(
  'profile/resend_mobile_verification_otp',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/users/assisted/profile/sendVerifyEmailUpdateOtp',
        credentials
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchRelationshipManagerDetails = createAsyncThunk(
  'profile/fetch_relationship_manager_details',
  async (RMId, { rejectWithValue }) => {
    try {
      const response = await api.get(`users/assisted/profile`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateBranchDetail = createAsyncThunk(
  'profile/updateBranch',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/users/rm/profile/updateBranch',
        credentials
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const intial = {
  email: '',
  mobileNo: '',
  relationshipManagerDetails: {
    id: 0,
    relationshipManagerId: '',
    firstName: '',
    middleName: null,
    lastName: '',
    email: '',
    mobileNo: '',
    location: '',
    profilePhoto: ''
  },
  isFetchRelationshipMangerDetailsLoading: false,
  isFetchRelationshipMangerDetailsSuccess: false,
  isFetchRelationshipMangerDetailsFailed: false,
  fetchRelationshipMangerDetailsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  investmentDetails: [],
  isSendEmailVerificationOtpLoading: false,
  isSendEmailVerificationOtpSuccess: false,
  isSendEmailVerificationOtpFailed: false,
  sendEmailVerificationErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isVerifyAndUpdateEmailLoading: false,
  isVerifyAndUpdateEmailSuccess: false,
  isVerifyAndUpdateEmailFailed: false,
  verifyAndUpdateEmailErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isSendMobileNoVerificationOtpLoading: false,
  isSendMobileNoVerificationOtpSuccess: false,
  isSendMobileNoVerificationOtpFailed: false,
  sendMobileNoVerificationOtpErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isVerifyAndUpdateMobileNoLoading: false,
  isVerifyAndUpdateMobileNoSuccess: false,
  isVerifyAndUpdateMobileNoFailed: false,
  verifyAndUpdateMobileNoErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isReSendEmailVerificationOtpLoading: false,
  isReSendEmailVerificationOtpSuccess: false,
  isReSendEmailVerificationOtpFailed: false,
  reSendEmailVerificationErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isReSendMobileNoVerificationOtpLoading: false,
  isReSendMobileNoVerificationOtpSuccess: false,
  isReSendMobileNoVerificationOtpFailed: false,
  reSendMobileNoVerificationOtpErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isBranchUpdateLoading: false,
  isBranchUpdateSuccess: false,
  isBranchUpdateFailed: false,
  branchUpdateErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(logoutAndClearToken, () => {
        return intial;
      })
      .addCase(setMobileNo, (state, action) => {
        state.mobileNo = action.payload;
      })
      .addCase(setEmailId, (state, action) => {
        state.email = action.payload;
      })
      .addCase(clearNomineeDetailsForProfile, state => {
        (state.isFetchNomineeDetailsSuccess = false),
          (state.isFetchNomineeDetailsFailed = false),
          (state.isUpdateNomineeDetailsSuccess = false),
          (state.isUpdateNomineeDetailsFailed = false);
      })
      .addCase(clearReferRequestState, state => {
        (state.isSendReferRequestSuccess = false),
          (state.isSendReferRequestFailed = false);
      })
      .addCase(clearRaiseComplaintState, state => {
        (state.isRaiseComplaintRequestSuccess = false),
          (state.isRaiseComplaintRequestFailed = false);
      })
      .addCase(clearReInvestmentDetails, state => {
        (state.isFetchInvestmentDetailsSuccess = false),
          (state.isFetchInvestmentDetailsFailed = false),
          (state.isSendReInvestmentRequestFailed = false),
          (state.isSendReInvestmentRequestSuccess = false),
          (state.isSendWithdrawalRequestFailed = false),
          (state.isSendWithdrawalRequestSuccess = false);
      })
      .addCase(clearBankDetails, state => {
        state.bankDetails = {
          accountHolderName: '',
          accountType: '',
          accountNo: '',
          confirmAccountNo: '',
          ifsc: '',
          branch: '',
          bank: '',
          address: ''
        };
        state.isFetchBankDetailsSuccess = false;
        state.isFetchBankDetailsFailed = false;
        state.isNameMatched = false;
        state.isNameMatchedSuccess = false;
        state.isNameMatchedFailed = false;
      })
      .addCase(clearPennyDropVerificationInProfile, state => {
        state.isFetchBankDetailsSuccess = false;
        state.isFetchBankDetailsFailed = false;
        state.isFetchExistingBankDetailsFailed = false;
        state.isFetchExistingBankDetailsSuccess = false;
        state.isVerifyChequeOcrDetailsFailed = false;
        state.isVerifyChequeOcrDetailsSuccess = false;
        state.isUpdateBankDetailsSuccess = false;
        state.isUpdateBankDetailsFailed = false;
      })
      .addCase(clearProfileState, state => {
        (state.isSendEmailVerificationOtpSuccess = false),
          (state.isSendEmailVerificationOtpFailed = false),
          (state.isSendMobileNoVerificationOtpSuccess = false),
          (state.isSendMobileNoVerificationOtpFailed = false),
          (state.isVerifyAndUpdateEmailSuccess = false),
          (state.isVerifyAndUpdateEmailFailed = false),
          (state.isVerifyAndUpdateMobileNoSuccess = false),
          (state.isVerifyAndUpdateMobileNoFailed = false),
          (state.isReSendEmailVerificationOtpSuccess = false),
          (state.isReSendEmailVerificationOtpFailed = false),
          (state.isReSendMobileNoVerificationOtpSuccess = false),
          (state.isReSendMobileNoVerificationOtpFailed = false);
        state.isBranchUpdateSuccess = false;
        state.isBranchUpdateFailed = false;
      })
      .addCase(sendEmailVerificationOtp.pending, state => {
        state.isSendEmailVerificationOtpLoading = true;
        (state.isSendEmailVerificationOtpSuccess = false),
          (state.isSendEmailVerificationOtpFailed = false),
          (state.sendEmailVerificationErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(sendEmailVerificationOtp.fulfilled, (state, action) => {
        (state.isSendEmailVerificationOtpLoading = false),
          (state.isSendEmailVerificationOtpSuccess = true),
          (state.email = action?.payload?.email);
      })
      .addCase(sendEmailVerificationOtp.rejected, (state, action) => {
        (state.isSendEmailVerificationOtpLoading = false),
          (state.isSendEmailVerificationOtpSuccess = false),
          (state.isSendEmailVerificationOtpFailed = true),
          (state.sendEmailVerificationErrorContainer = {
            ...state.sendEmailVerificationErrorContainer,
            ...action.payload
          });
      })

      .addCase(verifyAndUpdateEmail.pending, state => {
        state.isVerifyAndUpdateEmailLoading = true;
        (state.isVerifyAndUpdateEmailSuccess = false),
          (state.isVerifyAndUpdateEmailFailed = false),
          (state.verifyAndUpdateEmailErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyAndUpdateEmail.fulfilled, (state, action) => {
        const authdata = getAuthData();
        (state.isVerifyAndUpdateEmailLoading = false),
          (state.isVerifyAndUpdateEmailSuccess = true),
          (state.email = action?.payload?.email);
        if (action?.payload?.email) {
          setAuthData({ ...authdata, email: action?.payload?.email });
        }
      })
      .addCase(verifyAndUpdateEmail.rejected, (state, action) => {
        (state.isVerifyAndUpdateEmailLoading = false),
          (state.isVerifyAndUpdateEmailSuccess = false),
          (state.isVerifyAndUpdateEmailFailed = true),
          (state.verifyAndUpdateEmailErrorContainer = {
            ...state.verifyAndUpdateEmailErrorContainer,
            ...action.payload
          });
      })

      .addCase(sendMobileNoVerificationOtp.pending, state => {
        state.isSendMobileNoVerificationOtpLoading = true;
        (state.isSendMobileNoVerificationOtpSuccess = false),
          (state.isSendMobileNoVerificationOtpFailed = false),
          (state.sendMobileNoVerificationOtpErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(sendMobileNoVerificationOtp.fulfilled, (state, action) => {
        (state.isSendMobileNoVerificationOtpLoading = false),
          (state.isSendMobileNoVerificationOtpSuccess = true),
          (state.mobileNo = action?.payload?.mobileNo);
      })
      .addCase(sendMobileNoVerificationOtp.rejected, (state, action) => {
        (state.isSendMobileNoVerificationOtpLoading = false),
          (state.isSendMobileNoVerificationOtpSuccess = false),
          (state.isSendMobileNoVerificationOtpFailed = true),
          (state.sendMobileNoVerificationOtpErrorContainer = {
            ...state.sendMobileNoVerificationOtpErrorContainer,
            ...action.payload
          });
      })
      .addCase(verifyAndUpdateMobileNo.pending, state => {
        state.isVerifyAndUpdateMobileNoLoading = true;
        (state.isVerifyAndUpdateMobileNoSuccess = false),
          (state.isVerifyAndUpdateMobileNoFailed = false),
          (state.verifyAndUpdateMobileNoErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyAndUpdateMobileNo.fulfilled, (state, action) => {
        const authdata = getAuthData();
        (state.isVerifyAndUpdateMobileNoLoading = false),
          (state.isVerifyAndUpdateMobileNoSuccess = true),
          (state.mobileNo = action?.payload?.mobileNo);
        if (action?.payload?.mobileNo) {
          setAuthData({ ...authdata, mobileNo: action?.payload?.mobileNo });
        }
      })
      .addCase(verifyAndUpdateMobileNo.rejected, (state, action) => {
        (state.isVerifyAndUpdateMobileNoLoading = false),
          (state.isVerifyAndUpdateMobileNoSuccess = false),
          (state.isVerifyAndUpdateMobileNoFailed = true),
          (state.verifyAndUpdateMobileNoErrorContainer = {
            ...state.verifyAndUpdateMobileNoErrorContainer,
            ...action.payload
          });
      })
      .addCase(reSendEmailVerificationOtp.pending, state => {
        state.isSendEmailVerificationOtpSuccess = false;
        state.isSendEmailVerificationOtpFailed = false;
        state.isSendMobileNoVerificationOtpSuccess = false;
        state.isSendMobileNoVerificationOtpFailed = false;
        state.isReSendEmailVerificationOtpLoading = true;
        (state.isReSendEmailVerificationOtpSuccess = false),
          (state.isReSendEmailVerificationOtpFailed = false),
          (state.reSendEmailVerificationErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(reSendEmailVerificationOtp.fulfilled, (state, action) => {
        (state.isReSendEmailVerificationOtpLoading = false),
          (state.isReSendEmailVerificationOtpSuccess = true),
          (state.email = action?.payload?.email);
      })
      .addCase(reSendEmailVerificationOtp.rejected, (state, action) => {
        (state.isReSendEmailVerificationOtpLoading = false),
          (state.isReSendEmailVerificationOtpSuccess = false),
          (state.isReSendEmailVerificationOtpFailed = true),
          (state.reSendEmailVerificationErrorContainer = {
            ...state.reSendEmailVerificationErrorContainer,
            ...action.payload
          });
      })
      .addCase(reSendMobileNoVerificationOtp.pending, state => {
        state.isSendEmailVerificationOtpSuccess = false;
        state.isSendEmailVerificationOtpFailed = false;
        state.isSendMobileNoVerificationOtpSuccess = false;
        state.isSendMobileNoVerificationOtpFailed = false;
        state.isReSendMobileNoVerificationOtpLoading = true;
        (state.isReSendMobileNoVerificationOtpSuccess = false),
          (state.isReSendMobileNoVerificationOtpFailed = false),
          (state.reSendMobileNoVerificationOtpErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(reSendMobileNoVerificationOtp.fulfilled, (state, action) => {
        (state.isReSendMobileNoVerificationOtpLoading = false),
          (state.isReSendMobileNoVerificationOtpSuccess = true),
          (state.mobileNo = action?.payload?.mobileNo);
      })
      .addCase(reSendMobileNoVerificationOtp.rejected, (state, action) => {
        (state.isReSendMobileNoVerificationOtpLoading = false),
          (state.isReSendMobileNoVerificationOtpSuccess = false),
          (state.isReSendMobileNoVerificationOtpFailed = true),
          (state.reSendMobileNoVerificationOtpErrorContainer = {
            ...state.reSendMobileNoVerificationOtpErrorContainer,
            ...action.payload
          });
      })
      .addCase(resetPreDataOfOcrInProfile, state => {
        (state.bankDetails.accountNo = ''), (state.bankDetails.ifsc = '');
      })

      .addCase(fetchRelationshipManagerDetails.pending, state => {
        state.isFetchRelationshipMangerDetailsLoading = true;
        (state.isFetchRelationshipMangerDetailsSuccess = false),
          (state.isFetchRelationshipMangerDetailsFailed = false),
          (state.raiseComplaintRequestErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(fetchRelationshipManagerDetails.fulfilled, (state, action) => {
        state.isFetchRelationshipMangerDetailsLoading = false;
        state.isFetchRelationshipMangerDetailsSuccess = true;
        state.relationshipManagerDetails = action.payload;
      })
      .addCase(fetchRelationshipManagerDetails.rejected, (state, action) => {
        (state.isFetchRelationshipMangerDetailsLoading = false),
          (state.isFetchRelationshipMangerDetailsSuccess = false),
          (state.isFetchRelationshipMangerDetailsFailed = true),
          (state.fetchRelationshipMangerDetailsErrorContainer = {
            ...state.fetchRelationshipMangerDetailsErrorContainer,
            ...action.payload
          });
      })
      .addCase(updateBranchDetail.pending, state => {
        state.isBranchUpdateLoading = true;
        state.isBranchUpdateSuccess = false;
        state.isBranchUpdateFailed = false;
        state.branchUpdateErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(updateBranchDetail.fulfilled, state => {
        state.isBranchUpdateLoading = false;
        state.isBranchUpdateSuccess = true;
      })
      .addCase(updateBranchDetail.rejected, (state, action) => {
        state.isBranchUpdateLoading = false;
        state.isBranchUpdateSuccess = false;
        state.isBranchUpdateFailed = true;
        state.branchUpdateErrorContainer = {
          ...state.branchUpdateErrorContainer,
          ...action.payload
        };
      });
  }
});

export default profileSlice.reducer;
