import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, apiNoAuth, multipartApi } from '../../config/api';
import {
  clearAuthData,
  clearToken,
  setAuthData,
  setToken
} from '../../config/cookie';
import axios from 'axios';
export const revertAll = createAction('REVERT_ALL');
export const setAuthDetailsByLocalStorage = createAction('SET_AUTH_DETAILS');
export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');
export const clearLoginState = createAction('CLEAR_LOGIN_STATE');

const setTokenToApi = token => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  multipartApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const logoutUser = createAsyncThunk(
  'auth/logout',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post('/logout', credentials);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const pingServer = createAsyncThunk(
  'auth/actuator/ping',
  async (_, { rejectWithValue }) => {
    try {
      let url = process.env.REACT_APP_BASE_API_URL;
      const parts = url.split('/api/v1');
      const trimmedURl = parts[0];
      const response = await axios.get(`${trimmedURl}/actuator/health`);
      return response.data;
    } catch (error) {
      if (
        error &&
        error?.message &&
        String(error?.message).includes('Network Error')
      ) {
        return { status: 'DOWN' };
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Added by atul
export const sendOtp = createAsyncThunk(
  'rm/login/otp',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post(`/assisted/login/otp`, credentials);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resendOtpApi = createAsyncThunk(
  'rm/login/resendOtp',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post(`/assisted/login/otp`, credentials);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  'login/verifyOTP',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post(
        '/assisted/login/verifyOTP',
        credentials
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Added by atul

const intial = {
  isLoading: false,
  isLoggedIn: false,
  authData: {
    jwtToken: '',
    role: '',
    email: '',
    mobileNo: '',
    fullName: '',
    userId: 0
  },
  isError: false,
  errorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  applicationStatus: 'UP',
  isPingServerLoading: false,
  isPingServerSuccess: false,
  isPingServerFailed: false,

  isOtpRequestSucess: false,

  isOtpResendLoading: false,
  isOtpResendSuccess: false,
  isOtpResendFailed: false,

  isOtpVerifyLoading: false,
  isOtpVerifySuccess: false,
  isOtpVerifyFailed: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(logoutUser.pending, state => {
        (state.isLoading = true),
          (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(logoutUser.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        clearToken();
        (state.isLoading = false),
          (state.isLoggedIn = false),
          (state.isError = true),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })
      .addCase(revertAll, () => {
        return intial;
      })
      .addCase(logoutAndClearToken, () => {
        clearToken();
        clearAuthData();
        return intial;
      })
      .addCase(clearLoginState, state => {
        (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(setAuthDetailsByLocalStorage, (state, action) => {
        (state.isLoading = false),
          (state.isLoggedIn = true),
          (state.authData.userId = Number(action.payload['userId'])),
          (state.authData.role = action.payload['role']),
          (state.authData.fullName = action.payload['fullName']),
          (state.authData.mobileNo = action.payload['mobileNo']),
          (state.authData.email = action.payload['email']);
      })
      .addCase(pingServer.pending, state => {
        (state.isPingServerLoading = true), (state.isPingServerFailed = false);
      })
      .addCase(pingServer.fulfilled, (state, action) => {
        (state.isPingServerLoading = false),
          (state.isPingServerSuccess = true),
          (state.applicationStatus = action.payload?.status);
      })
      .addCase(pingServer.rejected, state => {
        (state.isPingServerLoading = false), (state.isPingServerFailed = true);
      })
      .addCase(sendOtp.pending, state => {
        state.isLoading = true;
        (state.isOtpRequestSucess = false),
          (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        (state.isLoading = false),
          (state.isOtpRequestSucess = true),
          (state.authData = { ...state.authData, ...action.payload });
      })
      .addCase(sendOtp.rejected, (state, action) => {
        (state.isLoading = false),
          (state.isOtpRequestSucess = false),
          (state.isError = true),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })
      .addCase(verifyOtp.pending, state => {
        state.isOtpVerifyLoading = true;
        state.isOtpVerifySuccess = false;
        state.isOtpVerifyFailed = false;
        (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        setTokenToApi(action.payload?.jwtToken);
        clearToken();
        clearAuthData();
        setToken(action.payload?.jwtToken);
        setAuthData({
          userId: action.payload?.userId,
          role: action.payload?.role,
          fullName: action.payload?.fullName,
          mobileNo: action.payload?.mobileNo,
          email: action.payload?.email,
          isCreditUser: action.payload?.isCreditUser
        }),
          (state.isLoggedIn = true),
          (state.isOtpVerifyLoading = false),
          (state.isOtpVerifySuccess = true),
          (state.isOtpVerifyFailed = false),
          (state.isOtpRequestSucess = false),
          (state.isOtpResendLoading = false),
          (state.isOtpResendSuccess = false),
          (state.isOtpResendFailed = false),
          (state.authData = {
            ...state.authData,
            ...action.payload
          });
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        (state.isLoggedIn = false),
          (state.isOtpVerifySuccess = true),
          (state.isOtpVerifyFailed = false),
          (state.isError = true),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })
      .addCase(resendOtpApi.pending, state => {
        state.isOtpResendLoading = true;
        state.isOtpResendSuccess = false;
        state.isOtpResendFailed = false;
        state.isError = false;
        state.errorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(resendOtpApi.fulfilled, (state, action) => {
        state.isOtpResendLoading = false;
        state.isOtpResendSuccess = true;
        state.authData = { ...state.authData, ...action.payload };
      })
      .addCase(resendOtpApi.rejected, (state, action) => {
        state.isOtpResendLoading = false;
        state.isOtpResendFailed = true;
        state.isOtpResendSuccess = false;
        state.isError = true;
        state.errorContainer = {
          ...state.errorContainer,
          ...action.payload
        };
      });
  }
});

export default authSlice.reducer;
