import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createTitle } from '../utils/utils';
import {
  logoutAndClearToken,
  setLayoutTitle
} from '../redux/reducers/layoutSlice';
import {
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Box,
  Button,
  Slide,
  Typography,
  DialogActions
} from '@mui/material';
import SkeletonBars from '../components/SkeletonBars';
import RMProfile from '../containers/profile/RMProfile';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmBox({ subtitle, handleClose, isOpen, handleSubmit }) {
  return (
    <>
      <Dialog
        maxWidth="xs"
        open={isOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason);
          }
        }}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        scroll="body"
        className="custom-dialog"
      >
        <DialogContent
          sx={{ padding: '1rem' }}
          className="MuiDialogContentRootc"
        >
          <DialogContentText>
            <Typography variant="body2" fontWeight={600} align="left">
              {subtitle}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: '1rem' }}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleClose}
                autoFocus
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleSubmit}>
                Logout
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

function Profile() {
  // constants
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // states

  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // useEffects
  useEffect(() => {
    // page title
    document.title = createTitle('My Profile');

    // set bar title
    dispatch(
      setLayoutTitle({
        title: 'My Profile',
        backwardLinkText: 'Go to Homepage',
        backwardLink: '/homepage'
      })
    );
    setIsLoading(false);
  }, []);

  // Modal
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    dispatch(logoutAndClearToken());
    navigate('/login');
    setIsModalOpen(false);
  };
  // Modal

  return (
    <Container disableGutters component="main" maxWidth="xs">
      <>
        {isLoading ? (
          <SkeletonBars />
        ) : (
          <Grid
            container
            justifyContent="space-between"
            marginTop={2}
            paddingLeft={2}
            paddingRight={3}
          >
            <Grid item xs={12}>
              <Box sx={{ marginTop: '1rem' }}>
                <RMProfile />
              </Box>
            </Grid>
          </Grid>
        )}

        {isModalOpen && (
          <ConfirmBox
            subtitle={`Are you sure you want to Logout?`}
            isOpen={isModalOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        )}
      </>
    </Container>
  );
}

export default Profile;
