import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../config/api';
import { USER_TYPE } from '../../utils/constant';

export const setAgreementChanged = createAction('AGREEMENT_CHANGED');
export const resetAddLeadDetails = createAction('RESET_ADD_LEAD_DETAILS');
export const setEmailVerificationChanged = createAction(
  'EMAIL_VERIFICATION_CHANGED'
);
export const setConsentChanged = createAction('CONSENT_CHANGED');

export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');

export const clearAddLeadDetailsState = createAction(
  'CLEAR_ADD_LEAD_DETAILS_STATE'
);

export const clearAddLeadVerificationState = createAction(
  'CLEAR_ADD_LEAD_VERIFICATION_STATE'
);

export const manuallyUpdateAddLeadDetails = createAction(
  'UPDATE_ADD_LEAD_DETAILS_MANNUALLY'
);

export const addLeadDetails = createAsyncThunk(
  'addlead/addLeadDetails',
  async (leadDetails, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/users/assisted/registerLead`,
        leadDetails
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const leadOtpVerification = createAsyncThunk(
  'addlead/leadOtpVerification',
  async (leadDetails, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/users/assisted/registerLeadToProspect`,
        leadDetails
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const intial = {
  addLeadData: {
    id: '',
    fullname: '',
    email: '',
    mobileNo: '',
    isEmailVerified: false,
    isAgreementChecked: false,
    isConsentChecked: false,
    isEmailChecked: false
  },
  isAddLeadDataLoading: false,
  isAddLeadDataSuccess: false,
  isAddLeadDataFailed: false,
  leadDetailErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isLeadVerificationLoading: false,
  isLeadVerificationSuccess: false,
  isLeadVerificationFailed: false,
  leadVerificationErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const addLeadSlice = createSlice({
  name: 'addlead',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(setAgreementChanged, (state, action) => {
        state.addLeadData.isAgreementChecked = action.payload;
      })
      .addCase(setEmailVerificationChanged, (state, action) => {
        state.addLeadData.isEmailChecked = action.payload;
      })
      .addCase(setConsentChanged, (state, action) => {
        state.addLeadData.isConsentChecked = action.payload;
      })
      .addCase(addLeadDetails.pending, state => {
        state.isAddLeadDataLoading = true;
        (state.isAddLeadDataSuccess = false),
          (state.isAddLeadDataFailed = false),
          (state.leadDetailErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(addLeadDetails.fulfilled, (state, action) => {
        (state.isAddLeadDataLoading = false),
          (state.isAddLeadDataSuccess = true),
          (state.isAddLeadDataFailed = false),
          (state.addLeadData = action.payload);
      })
      .addCase(addLeadDetails.rejected, (state, action) => {
        (state.isAddLeadDataLoading = false),
          (state.isAddLeadDataSuccess = false),
          (state.isAddLeadDataFailed = true),
          (state.leadDetailErrorContainer = {
            ...state.leadDetailErrorContainer,
            ...action.payload
          });
      })

      .addCase(leadOtpVerification.pending, state => {
        state.isLeadVerificationLoading = true;
        (state.isLeadVerificationSuccess = false),
          (state.isLeadVerificationFailed = false),
          (state.leadVerificationErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(leadOtpVerification.fulfilled, (state, action) => {
        (state.isLeadVerificationLoading = false),
          (state.isLeadVerificationSuccess = true),
          (state.isLeadVerificationFailed = false),
          (state.addLeadData.isEmailVerified =
            action?.payload?.isEmailVerified);
      })
      .addCase(leadOtpVerification.rejected, (state, action) => {
        (state.isLeadVerificationLoading = false),
          (state.isLeadVerificationSuccess = false),
          (state.isLeadVerificationFailed = true),
          (state.leadVerificationErrorContainer = {
            ...state.leadVerificationErrorContainer,
            ...action.payload
          });
      })
      .addCase(logoutAndClearToken, () => {
        return intial;
      })
      .addCase(resetAddLeadDetails, () => {
        return intial;
      })
      .addCase(clearAddLeadDetailsState, state => {
        (state.isAddLeadDataSuccess = false),
          (state.isAddLeadDataFailed = false);
      })
      .addCase(clearAddLeadVerificationState, state => {
        (state.isLeadVerificationSuccess = false),
          (state.isLeadVerificationFailed = false),
          (state.isResendOtpSucess = false),
          (state.isResendOtpFailed = false);
      })
      .addCase(manuallyUpdateAddLeadDetails, (state, action) => {
        state.addLeadData = action.payload;
      });
  }
});

export default addLeadSlice.reducer;
