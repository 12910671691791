import { useEffect, useState } from 'react';
import { createTitle, handleError } from '../utils/utils';
import { FormContent } from '../utils/styledLayout';
import {
  Container,
  Grid,
  Paper,
  Tooltip,
  Typography,
  Hidden,
  Card,
  CardContent
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  dashboardIncentiveGraph,
  setLayoutTitle
} from '../redux/reducers/layoutSlice';
import { IMG_LENDBOX, IMG_RBI } from '../utils/imageUrls';
import { setBackButtonActionFalse } from '../redux/reducers/investorSlice';
import {
  colorDarkGray,
  colorGolden,
  colorGrayish,
  colorPrimaryBlue
} from '../config/theme';
import DashboardIncentiveGraph from '../containers/dashboard/DashboardIncentiveGraph';
import SnackBarBox from '../components/SnackBarBox';
import CircleIcon from '@mui/icons-material/Circle';
import { Chart } from 'react-google-charts';
import './chart.css'; // Import the CSS file

const cardContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: '0',
  backgroundColor: 'red!important'
};

const cardStyles = {
  textAlign: 'center',
  width: '100%',
  height: '0',
  paddingBottom: '100%',
  position: 'relative',
  backgroundColor: '#FEC310',
  borderRadius: '24px',
  boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.19)'
};

const imageStyles = {
  width: '60%',
  height: '60%',
  borderRadius: '50%',
  backgroundColor: 'white',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '8px'
};

const textStyles = {
  position: 'absolute',
  bottom: '0',
  left: '50%',
  transform: 'translateX(-50%)',
  fontWeight: 'bold'
};

const typographyContainerStyles = {
  display: 'flex',
  height: '100%',
  padding: '0'
};

const cardStyleGrey = {
  backgroundColor: '#F5F5F5',
  marginTop: '10px',
  marginBottom: '10px',
  borderRadius: '10px'
};

const testData = [
  ['Month', 'Investment ', 'Borrower Target', 'Achieved', 'Borrower Achieved'],
  ['January', 83333.33, 50000.0, 75000.0, 30000.0],
  ['February', 83333.33, 50000.0, 70000.0, 35000.0],
  ['March', 83333.33, 50000.0, 82000.0, 45000.0],
  ['April', 83333.33, 50000.0, 78000.0, 40000.0],
  ['May', 83333.33, 50000.0, 80000.0, 42000.0],
  ['June', 83333.33, 50000.0, 83000.0, 47000.0],
  ['July', 83333.33, 50000.0, 79000.0, 43000.0],
  ['August', 83333.33, 50000.0, 76000.0, 39000.0],
  ['September', 83333.33, 50000.0, 81000.0, 41000.0],
  ['October', 83333.33, 50000.0, 80000.0, 42000.0],
  ['November', 83333.33, 50000.0, 85000.0, 46000.0],
  ['December', 83333.33, 50000.0, 84000.0, 48000.0]
];

// const CustomLegend = ({ legendItems }) => (
//   <div
//     style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}
//   >
//     {legendItems.map((item, index) => (
//       <div
//         key={index}
//         style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}
//       >
//         <div
//           style={{
//             width: '12px',
//             height: '12px',
//             backgroundColor: item.color,
//             marginRight: '8px'
//           }}
//         />
//         <span style={{ fontSize: '12px' }}>{item.text}</span>
//       </div>
//     ))}
//   </div>
// );

const CustomLegend = ({ legendItems, shape = 'rectangle' }) => (
  <div
    style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}
  >
    {legendItems.map((item, index) => (
      <div
        key={index}
        style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}
      >
        <div
          style={{
            width: shape === 'circle' ? '12px' : '16px',
            height: '12px',
            backgroundColor: item.color,
            marginRight: '8px',
            borderRadius: shape === 'circle' ? '50%' : '0'
          }}
        />
        <span style={{ fontSize: '12px' }}>{item.text}</span>
      </div>
    ))}
  </div>
);

const ChartComponent = ({ chartData }) => {
  const options = {
    title: 'Monthly',
    titleTextStyle: {
      fontSize: 13,
      bold: true,
      italic: false,
      color: '#000'
    },
    chartArea: { left: '15%', width: '80%' },
    hAxis: {
      title: 'Month',
      titleTextStyle: {
        fontSize: 12,
        italic: false,
        bold: true
      },
      textStyle: { fontSize: 10, italic: false },
      format: 'MMM',
      showTextEvery: 1,
      slantedText: true,
      slantedTextAngle: 45,
      ticks: [
        { v: new Date(2023, 0), f: 'Jan' },
        { v: new Date(2023, 1), f: 'Feb' },
        { v: new Date(2023, 2), f: 'Mar' },
        { v: new Date(2023, 3), f: 'Apr' },
        { v: new Date(2023, 4), f: 'May' },
        { v: new Date(2023, 5), f: 'Jun' },
        { v: new Date(2023, 6), f: 'Jul' },
        { v: new Date(2023, 7), f: 'Aug' },
        { v: new Date(2023, 8), f: 'Sep' },
        { v: new Date(2023, 9), f: 'Oct' },
        { v: new Date(2023, 10), f: 'Nov' },
        { v: new Date(2023, 11), f: 'Dec' }
      ]
    },
    vAxis: {
      title: 'Amount',
      minValue: 0,
      format: 'short',
      textStyle: { fontSize: 12, italic: false },
      titleTextStyle: { fontSize: 12, italic: false, bold: true },
      viewWindow: {
        min: 0,
        max: 120000
      },
      gridlines: { count: 7 },
      ticks: [0, 20000, 40000, 60000, 80000, 100000, 120000]
    },
    seriesType: 'bars',
    series: {
      0: { type: 'bars', color: colorGolden }, // Investment Target (bar)
      1: { type: 'bars', color: colorPrimaryBlue }, // Borrower Target (bar)
      2: { type: 'line', color: colorPrimaryBlue }, // Investment Achieved (line)
      3: { type: 'line', color: colorGolden } // Borrower Achieved (line)
    },
    legend: {
      position: 'bottom',
      alignment: 'center',
      textStyle: { fontSize: 12 }
    }
  };

  const legendStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    fontSize: '12px' // Adjust font size as needed
  };

  const legendItemStyle = {
    flex: '1 0 50%', // Two items per line
    textAlign: 'center'
  };
  return (
    <Chart
      width={'100%'}
      height={'400px'}
      chartType="ComboChart"
      loader={<div>Loading Chart...</div>}
      data={chartData}
      options={options}
      chartWrapperProps={{
        legendStyle: legendStyle,
        legendItemStyle: legendItemStyle
      }}
      className="chart"
    />
  );
};

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authData } = useSelector(state => state.auth);
  const {
    isDashboardIncentiveGraphData,
    DashboardIncentiveGraphErrorContainer
  } = useSelector(state => state.layout);
  const [isLoadingDash, setIsLoadingDash] = useState(true);

  const sessionData = JSON.parse(
    sessionStorage.getItem('x-auth-token-assisted') || '{}'
  );
  const { isCreditUser } = sessionData;

  useEffect(() => {
    dispatch(setBackButtonActionFalse());
    document.title = createTitle('Dashboard');
    dispatch(
      setLayoutTitle({
        title: 'Dashboard'
      })
    );
    dispatch(dashboardIncentiveGraph({ userId: authData.userId }));
    setIsLoadingDash(false);
  }, []);

  const handleFlowControl = user => {
    if (user.toLowerCase() === 'investor') {
      navigate('/lead-management-investor');
    } else if (user.toLowerCase() === 'borrower') {
      navigate('/lead-management-borrower');
    }
  };

  const legendItems = [
    { color: colorGolden, text: 'Target' },
    { color: colorPrimaryBlue, text: 'Achieved' }
  ];
  // // const data = isDashboardIncentiveGraphData?.targetGraph;
  // const getFilteredData = (data, isCreditUser) => {
  //   if (isCreditUser) {
  //     return data; // Return all data if isCreditUser is true
  //   }

  //   // Filter out the "Borrower Target" and "Borrower Achieved" if isCreditUser is false
  //   return data.filter(
  //     item =>
  //       item.name === 'Investment Target' || item.name === 'Investment Achieved'
  //   );
  // };
  // const chartData = getFilteredData(
  //   isDashboardIncentiveGraphData?.targetGraph,
  //   isCreditUser
  // );

  const filterChartData = (originalData, isCreditUser) => {
    if (isCreditUser) {
      // Return the original data as it is
      return originalData;
    } else {
      // Filter out the Borrower columns
      return originalData?.map(row => {
        // Keep only "Month", "Investment Target", and "Investment Achieved"
        if (row[0] === 'Month') {
          return ['Month', 'Investment Target', 'Investment Achieved'];
        } else {
          return [row[0], row[1], row[3]];
        }
      });
    }
  };

  const filteredChartData = filterChartData(
    isDashboardIncentiveGraphData?.targetGraph,
    isCreditUser
  );

  return (
    <>
      {isLoadingDash ? (
        <Loader text="We welcome you to our homepage." />
      ) : (
        <>
          <Container className="container_css">
            <FormContent>
              <div style={typographyContainerStyles}>
                <Typography style={{ fontSize: 24 }} variant="subtitle1">
                  Welcome,
                  <span style={{ marginLeft: 5, fontWeight: 'bold' }}>
                    {authData.fullName || ''}
                  </span>
                </Typography>
              </div>
              <Grid container spacing={3} paddingTop={3}>
                <Grid item xs={6} md={6} lg={6}>
                  <Tooltip
                    placement="top"
                    title="Begin your journey as a investor."
                  >
                    <div style={cardContainerStyles}>
                      <Paper
                        onClick={() => handleFlowControl('investor')}
                        className="dashboard_card"
                        elevation={3}
                        style={cardStyles}
                      >
                        <img
                          src="/images/investor_icon.svg"
                          alt="Card 2"
                          style={imageStyles}
                        />
                        <Typography style={textStyles} variant="subtitle1">
                          Investor
                        </Typography>
                      </Paper>
                    </div>
                  </Tooltip>
                </Grid>
                {isCreditUser && (
                  <Grid item xs={6} md={6} lg={6}>
                    <Tooltip
                      placement="top"
                      title="Begin your journey as a borrower."
                    >
                      <div style={cardContainerStyles}>
                        <Paper
                          onClick={() => handleFlowControl('borrower')}
                          elevation={3}
                          className="dashboard_card"
                          style={cardStyles}
                        >
                          <img
                            src="/images/borrower_icon.svg"
                            alt="Card 1"
                            style={imageStyles}
                          />
                          <Typography style={textStyles} variant="subtitle1">
                            Borrower
                          </Typography>
                        </Paper>
                      </div>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
              <Card
                elevation={0}
                sx={{ ...cardStyleGrey, marginTop: '3rem' }}
                className="dashboard_card"
              >
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs>
                      <Typography
                        fontSize={17}
                        variant="body2"
                        align="left"
                        fontWeight={600}
                      >
                        Annual (Investor)
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop={2}
                  >
                    <Grid item xs sx={{ display: 'inline-flex' }}>
                      <CircleIcon sx={{ color: colorGolden }} />
                      <Typography
                        fontSize={17}
                        variant="body2"
                        align="left"
                        fontWeight={600}
                        color={colorGrayish}
                        marginLeft={1}
                      >
                        Target
                      </Typography>
                    </Grid>

                    <Grid item xs sx={{ display: 'inline-flex' }}>
                      <CircleIcon sx={{ color: colorDarkGray }} />
                      <Typography
                        fontSize={17}
                        variant="body2"
                        align="left"
                        fontWeight={600}
                        color={colorGrayish}
                        marginLeft={1}
                      >
                        Achieved
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop={2}
                  >
                    <Grid item xs>
                      <Typography
                        fontSize={17}
                        variant="subtitle2"
                        align="left"
                        fontWeight={600}
                        marginLeft={4}
                      >
                        ₹{' '}
                        {new Intl.NumberFormat('en-IN').format(
                          isDashboardIncentiveGraphData?.investorTargetAmount
                            ? isDashboardIncentiveGraphData?.investorTargetAmount
                            : 0
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        fontSize={17}
                        variant="subtitle2"
                        align="left"
                        fontWeight={600}
                        marginLeft={4}
                      >
                        ₹{' '}
                        {new Intl.NumberFormat('en-IN').format(
                          isDashboardIncentiveGraphData?.investorAchievedAmount
                            ? isDashboardIncentiveGraphData?.investorAchievedAmount
                            : 0
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {isCreditUser && (
                <Card
                  elevation={0}
                  sx={{ ...cardStyleGrey, marginTop: '3rem' }}
                  className="dashboard_card"
                >
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs>
                        <Typography
                          fontSize={17}
                          variant="body2"
                          align="left"
                          fontWeight={600}
                        >
                          Annual (Borrower)
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      marginTop={2}
                    >
                      <Grid item xs sx={{ display: 'inline-flex' }}>
                        <CircleIcon sx={{ color: colorGolden }} />
                        <Typography
                          fontSize={17}
                          variant="body2"
                          align="left"
                          fontWeight={600}
                          color={colorGrayish}
                          marginLeft={1}
                        >
                          Target
                        </Typography>
                      </Grid>

                      <Grid item xs sx={{ display: 'inline-flex' }}>
                        <CircleIcon sx={{ color: colorDarkGray }} />
                        <Typography
                          fontSize={17}
                          variant="body2"
                          align="left"
                          fontWeight={600}
                          color={colorGrayish}
                          marginLeft={1}
                        >
                          Achieved
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      marginTop={2}
                    >
                      <Grid item xs>
                        <Typography
                          fontSize={17}
                          variant="subtitle2"
                          align="left"
                          fontWeight={600}
                          marginLeft={4}
                        >
                          ₹{' '}
                          {new Intl.NumberFormat('en-IN').format(
                            isDashboardIncentiveGraphData?.borrowerTargetAmount
                              ? isDashboardIncentiveGraphData?.borrowerTargetAmount
                              : 0
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          fontSize={17}
                          variant="subtitle2"
                          align="left"
                          fontWeight={600}
                          marginLeft={4}
                        >
                          ₹{' '}
                          {new Intl.NumberFormat('en-IN').format(
                            isDashboardIncentiveGraphData?.borrowerAchievedAmount
                              ? isDashboardIncentiveGraphData?.borrowerAchievedAmount
                              : 0
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
              <ChartComponent chartData={filteredChartData} />
              {/* <ChartComponent
                chartData={isDashboardIncentiveGraphData?.targetGraph}

                // chartData={chartData}
              /> */}
              {/* <CustomLegend legendItems={legendItems} shape="circle" /> */}
            </FormContent>
          </Container>

          <Hidden mdDown>
            <Paper
              sx={{
                zIndex: '0 important',
                position: 'relative',
                bottom: 0,
                left: 0,
                right: 0,
                boxShadow: 'none',
                backgroundColor: 'white',
                margin: '3rem 1rem 0rem 1rem'
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={6} sx={{ textAlign: 'left' }}>
                  <img
                    src={IMG_LENDBOX}
                    alt="Powered by Lendbox"
                    height={50}
                    width={170}
                  />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  <img
                    src={IMG_RBI}
                    alt="Regulated by RBI"
                    height={50}
                    width={50}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Hidden>

          <Hidden mdUp>
            <Paper
              sx={{
                marginTop: '2rem',
                zIndex: '0 important',
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                boxShadow: 'none',
                backgroundColor: 'white'
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  xs={6}
                  sx={{ textAlign: 'left', paddingLeft: '1rem' }}
                >
                  <img
                    src={IMG_LENDBOX}
                    alt="Powered by Lendbox"
                    height={50}
                    width={170}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ textAlign: 'right', paddingRight: '1rem' }}
                >
                  <img
                    src={IMG_RBI}
                    alt="Regulated by RBI"
                    height={50}
                    width={50}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Hidden>
        </>
      )}

      {!isLoadingDash && DashboardIncentiveGraphErrorContainer?.error && (
        <SnackBarBox
          type="error"
          message={handleError(
            DashboardIncentiveGraphErrorContainer?.errorMessage
          )}
        />
      )}
    </>
  );
}

export default Dashboard;
