import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormLayout } from '../utils/styledLayout';
import React, { useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import { logoutAndClearToken } from '../redux/reducers/authSlice';
import PropTypes from 'prop-types';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { setBackButtonActionFalse } from '../redux/reducers/investorSlice';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // Placeholder icon

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmBox({ subtitle, handleClose, isOpen, handleSubmit }) {
  return (
    <>
      <Dialog
        maxWidth="xs"
        open={isOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason);
          }
        }}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        scroll="body"
        className="custom-dialog"
      >
        <DialogContent
          sx={{ padding: '1rem' }}
          className="MuiDialogContentRootc"
        >
          <DialogContentText>
            <Typography variant="body2" fontWeight={600} align="left">
              {subtitle}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: '1rem' }}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleClose}
                autoFocus
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleSubmit}>
                Logout
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
function Layout({
  children,
  appbar = true,
  layout = {},
  avtar = false,
  backButton = true
}) {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    dispatch(logoutAndClearToken());
    navigate('/login');
    return;
  };

  const handleNavigation = route => {
    handleClose();
    navigate(route);
  };

  const handleNavigate = () => {
    if (layout?.backwardLink && layout?.backwardLink.startsWith('/')) {
      if (
        layout?.backwardLink &&
        layout?.backwardLink.startsWith('/homepage')
      ) {
        dispatch(setBackButtonActionFalse());
      }
      navigate(`${layout?.backwardLink}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <Container disableGutters>
        <FormLayout>
          {appbar && (
            <>
              <AppBar
                position="static"
                color="secondary"
                elevation={0}
                sx={{
                  paddingBottom: '0.3rem',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)'
                }}
              >
                <Toolbar>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={1} md={1} lg={1} xl={1}>
                      <Box>
                        {backButton &&
                          (layout?.backwardLinkText ||
                            layout?.backwardLink) && (
                            <Tooltip
                              title={
                                layout.backwardLinkText
                                  ? layout?.backwardLinkText
                                  : 'Back'
                              }
                            >
                              <IconButton
                                edge="start"
                                aria-label="back"
                                onClick={handleNavigate}
                              >
                                <ArrowBackIosIcon sx={{ marginLeft: '6px' }} />
                              </IconButton>
                            </Tooltip>
                          )}
                      </Box>
                    </Grid>
                    {/* <Grid item xs={2} md={2} lg={2} xl={2}></Grid> */}
                    <Grid item xs>
                      {Object.keys(layout).length > 0 && (
                        <Typography
                          variant="subtitle1"
                          fontWeight={700}
                          style={{ flexGrow: 1, textAlign: 'center' }}
                        >
                          {layout?.title}
                        </Typography>
                      )}
                    </Grid>
                    {/* <Grid item xs={2} md={2} lg={2} xl={2}> */}
                    {/* <WhatsappSupport /> */}
                    {/* </Grid> */}
                    {avtar && (
                      <Grid item xs={1} md={1} lg={1} xl={1}>
                        <Box style={{ marginRight: 2 }}>
                          <IconButton
                            onClick={handleClick}
                            size="small"
                            aria-controls={open ? 'profile-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                          >
                            <MenuOutlinedIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: 'visible',
                                filter:
                                  'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1
                                },
                                '&:before': {
                                  content: '""',
                                  display: 'block',
                                  position: 'absolute',
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: 'background.paper',
                                  transform: 'translateY(-50%) rotate(45deg)',
                                  zIndex: 0
                                }
                              }
                            }}
                            transformOrigin={{
                              horizontal: 'right',
                              vertical: 'top'
                            }}
                            anchorOrigin={{
                              horizontal: 'right',
                              vertical: 'bottom'
                            }}
                          >
                            <MenuItem
                              onClick={() => handleNavigation('/profile')}
                            >
                              <PermIdentityOutlinedIcon
                                sx={{ marginRight: '8px' }}
                              />
                              My Profile
                            </MenuItem>

                            <MenuItem onClick={() => setIsModalOpen(true)}>
                              <LogoutIcon sx={{ marginRight: '8px' }} />
                              Logout
                            </MenuItem>
                          </Menu>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Toolbar>
              </AppBar>
            </>
          )}
          {children}
        </FormLayout>
        {isModalOpen && (
          <ConfirmBox
            subtitle={`Are you sure you want to Logout?`}
            isOpen={isModalOpen}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        )}
      </Container>
    </>
  );
}
Layout.propTypes = {
  children: PropTypes.any,
  appbar: PropTypes.bool,
  layout: PropTypes.object,
  avtar: PropTypes.bool,
  drawer: PropTypes.bool,
  backButton: PropTypes.bool
};

export default Layout;
