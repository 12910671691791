import { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  enterEmailOrMobile,
  enterOnlyNumbers,
  handleError,
  isEmail,
  validateChar
} from '../../utils/utils';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import { FormButtonBottom } from '../../utils/styledLayout';
import { MuiOtpInput } from 'mui-one-time-password-input';
import OtpTimer from '../../components/OtpTimer';
import { verifyOtp } from '../../redux/reducers/authSlice';
import SnackBarBox from '../../components/SnackBarBox';
import { useDispatch } from 'react-redux';
import { apiNoAuth } from '../../config/api';

const OtpVerification = ({ username }) => {
  const formControl = {
    margin: '0.5rem auto'
  };
  const otpInputRef = useRef(null);
  const oneMinuteFromNow = 60;
  const dispatch = useDispatch();

  const [otpExpired, setOTPExpired] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [resendErrorContainer, setResendErrorContainer] = useState({});
  const [resendSuccess, setResendSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      otp: ''
    },
    validationSchema: yup.object({
      otp: yup
        .string()
        .test('otp', 'OTP must be of 4 digits', val => val.length === 4)
        .required('OTP is required.')
    }),
    onSubmit: values => {
      const loginData = isEmail(username)
        ? { email: username.trim(), emailOTP: values.otp.trim() }
        : {
            mobileNo: username.trim(),
            mobileOTP: values.otp.trim()
          };
      dispatch(verifyOtp(loginData));
    }
  });

  const resendOtp = async () => {
    setOTPExpired(false);
    formik.resetForm();
    if (otpExpired) {
      setisLoading(true);
      let requestPayload = isEmail(username)
        ? { email: username }
        : { mobileNo: username };

      await apiNoAuth
        .post(`/assisted/login/otp`, requestPayload)
        .then(() => {
          setResendSuccess(true);
        })
        .catch(err => {
          setResendErrorContainer(err.response.data);
        })
        .finally(() => {
          setisLoading(false);
          // setResendSuccess(true);
        });
    }
  };

  const handleOTPTimerExpired = () => {
    setOTPExpired(true);
  };

  return (
    <Box pt={2}>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Box>
          <Typography margin="1.5rem" align="center" variant="subtitle2">
            Enter the code we sent to {enterEmailOrMobile(username)}
          </Typography>

          <FormControl
            variant="outlined"
            fullWidth
            size="small"
            sx={formControl}
          >
            <MuiOtpInput
              ref={otpInputRef}
              className="otp_input_custom"
              value={formik.values.otp}
              onChange={newValue =>
                formik.setFieldValue('otp', enterOnlyNumbers(newValue))
              }
              validateChar={validateChar}
              TextFieldsProps={{
                inputMode: 'tel',
                pattern: '[0-9]*',
                type: 'tel'
              }}
            />
            {formik.touched.otp && formik.errors.otp && (
              <FormHelperText sx={{ textAlign: 'center' }}>
                {formik.errors.otp}
              </FormHelperText>
            )}
          </FormControl>

          {!otpExpired && (
            <OtpTimer
              expiryTimestamp={oneMinuteFromNow}
              onTimeout={handleOTPTimerExpired}
            />
          )}
          <Typography margin="1.5rem" align="center" variant="subtitle2">
            Didn&apos;t receive the OTP?
          </Typography>

          <Tooltip
            placement="top"
            title={otpExpired ? `Click to Resend the OTP` : ''}
          >
            <Typography sx={formControl} align="center" variant="body2">
              <Button
                size="small"
                variant="default"
                onClick={resendOtp}
                disabled={!otpExpired || isLoading}
              >
                {isLoading ? 'Sending otp' : 'RESEND OTP'}
              </Button>
            </Typography>
          </Tooltip>
        </Box>
        <Box>
          <FormButtonBottom>
            <Grid container spacing={2} style={{ justifyContent: 'center' }}>
              <Grid item xs={6}>
                <LoadingButton
                  fullWidth
                  style={{ textTransform: 'uppercase' }}
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                >
                  Verify OTP
                </LoadingButton>
              </Grid>
            </Grid>
          </FormButtonBottom>
        </Box>
      </form>

      {!isLoading && resendErrorContainer?.errorMessage && (
        <SnackBarBox
          type="error"
          message={handleError(resendErrorContainer?.errorMessage)}
        />
      )}
      {!isLoading && resendSuccess && (
        <SnackBarBox
          type="success"
          message={`An OTP has been resend to the registered : ${username}.`}
        />
      )}
    </Box>
  );
};

export default OtpVerification;
