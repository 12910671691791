import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, multipartApi } from '../../config/api';
import { IMG_PERSON_AVTAR_SMALL } from '../../utils/imageUrls';

export const revertAllLayoutFields = createAction('REVERT_ALL_LAYOUT_FIELDS');
export const setLayoutTitle = createAction('SET_LAYOUT_TITLE');
export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');
export const clearLayoutState = createAction('CLEAR_LAYOUT_STATE');

export const profilePicUpload = createAsyncThunk(
  'layout/profile/upload',
  async ({ data, rmId }, { rejectWithValue }) => {
    try {
      await multipartApi.post(`users/assisted/profile/image`, data);
    } catch (error) {
      if (error?.response && error?.response?.status === 500) {
        return rejectWithValue({
          error: false,
          errorMessage: 'Error caught while uploading profile picture.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      } else if (error?.response && error?.response?.status === 404) {
        return rejectWithValue({
          error: false,
          errorMessage: 'User not available.',
          description: 'Not Found',
          statusCode: error.response.status
        });
      } else if (error?.response && error?.response?.status === 400) {
        return rejectWithValue({
          error: false,
          errorMessage: `Error while uploading profile picture.`,
          description: 'Not Found',
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const profilePicView = createAsyncThunk(
  'layout/profile/view',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const imageSource = await api.get(`/images/profile/${userId}`, {
        responseType: 'blob'
      });

      if (imageSource) {
        const blobData = imageSource.data;
        const blobUrl = URL.createObjectURL(blobData);
        return blobUrl;
      }
    } catch (error) {
      if (error?.response && error?.response?.status === 500) {
        return rejectWithValue({
          error: false,
          errorMessage: 'Error caught while fetching profile picture.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      } else if (error?.response && error?.response?.status === 404) {
        return rejectWithValue({
          error: false,
          errorMessage: 'Profile picture not available.',
          description: 'Not Found',
          statusCode: error.response.status
        });
      } else if (error?.response && error?.response?.status === 400) {
        return rejectWithValue({
          error: false,
          errorMessage: `Error while fetching profile picture.`,
          description: 'Not Found',
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadInitialProfilePic = createAsyncThunk(
  'layout/profile-initial/view',
  async ({ data }, { rejectWithValue }) => {
    const { jwtToken, userId } = data;
    try {
      const imageSource = await api.get(`/images/profile/${userId}`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      });

      if (imageSource) {
        const blobData = imageSource.data;
        const blobUrl = URL.createObjectURL(blobData);
        return blobUrl;
      }
    } catch (error) {
      if (error?.response && error?.response?.status === 500) {
        return rejectWithValue({
          error: false,
          errorMessage: 'Error caught while fetching profile picture.',
          description: 'Something Went Wrong',
          statusCode: error.response.status
        });
      } else if (error?.response && error?.response?.status === 404) {
        return rejectWithValue({
          error: false,
          errorMessage: 'Profile picture not available.',
          description: 'Not Found',
          statusCode: error.response.status
        });
      } else if (error?.response && error?.response?.status === 400) {
        return rejectWithValue({
          error: false,
          errorMessage: `Error while fetching profile picture.`,
          description: 'Not Found',
          statusCode: error.response.status
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const profilePicDelete = createAsyncThunk(
  'layout/profile/delete',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.delete(`users/assisted/profile/image`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const dashboardIncentiveGraph = createAsyncThunk(
  '/users/assisted/dashboard/target',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/users/assisted/dashboard/target`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const intial = {
  title: '',
  backwardLink: '',
  backwardLinkText: '',
  forwardLink: '',
  profileImage: IMG_PERSON_AVTAR_SMALL,
  isUploadProfilePicLoading: false,
  isUploadProfilePicSuccess: false,
  isUploadProfilePicFailed: false,
  uploadProfilePicErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isFetchProfilePicLoading: false,
  isFetchProfilePicSuccess: false,
  isFetchProfilePicFailed: false,
  FetchProfilePicErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isDeleteProfilePicLoading: false,
  isDeleteProfilePicSuccess: false,
  isDeleteProfilePicFailed: false,
  DeleteProfilePicErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isDashboardIncentiveGraphData: null,
  isDashboardIncentiveGraphLoading: false,
  isDashboardIncentiveGraphSuccess: false,
  isDashboardIncentiveGraphFailed: false,
  DashboardIncentiveGraphErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: intial,
  extraReducers: builder => {
    builder.addCase(revertAllLayoutFields, () => {
      return intial;
    });
    builder
      .addCase(setLayoutTitle, (state, action) => {
        (state.title = action.payload?.title ? action.payload?.title : ''),
          (state.backwardLink = action.payload?.backwardLink
            ? action.payload?.backwardLink
            : ''),
          (state.backwardLinkText = action.payload?.backwardLinkText
            ? action.payload?.backwardLinkText
            : ''),
          (state.forwardLink = action.payload?.forwardLink
            ? action.payload?.forwardLink
            : state.forwardLink),
          (state.profileImage = action.payload?.profileImage
            ? action.payload?.profileImage
            : state.profileImage);
      })
      .addCase(logoutAndClearToken, () => {
        return intial;
      })
      .addCase(profilePicUpload.pending, state => {
        (state.isUploadProfilePicLoading = true),
          (state.isUploadProfilePicSuccess = false),
          (state.isUploadProfilePicFailed = false),
          (state.uploadProfilePicErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })
      .addCase(profilePicUpload.fulfilled, (state, action) => {
        (state.isUploadProfilePicLoading = false),
          (state.isUploadProfilePicFailed = false),
          (state.isUploadProfilePicSuccess = true),
          (state.profileImage = action.payload);
      })
      .addCase(profilePicUpload.rejected, (state, action) => {
        (state.isUploadProfilePicLoading = false),
          (state.isUploadProfilePicSuccess = false),
          (state.isUploadProfilePicFailed = true),
          (state.uploadProfilePicErrorContainer = {
            ...state.uploadProfilePicErrorContainer,
            ...action.payload
          });
      })
      .addCase(profilePicView.pending, state => {
        (state.isFetchProfilePicLoading = true),
          (state.isFetchProfilePicSuccess = false),
          (state.isFetchProfilePicFailed = false),
          (state.FetchProfilePicErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })
      .addCase(profilePicView.fulfilled, (state, action) => {
        (state.isFetchProfilePicLoading = false),
          (state.isFetchProfilePicFailed = false),
          (state.isFetchProfilePicSuccess = true),
          (state.profileImage = action.payload);
      })
      .addCase(profilePicView.rejected, (state, action) => {
        (state.isFetchProfilePicLoading = false),
          (state.isFetchProfilePicSuccess = false),
          (state.isFetchProfilePicFailed = true),
          (state.FetchProfilePicErrorContainer = {
            ...state.FetchProfilePicErrorContainer,
            ...action.payload
          });
      })
      .addCase(loadInitialProfilePic.pending, state => {
        (state.isFetchProfilePicLoading = true),
          (state.isFetchProfilePicSuccess = false),
          (state.isFetchProfilePicFailed = false),
          (state.FetchProfilePicErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })
      .addCase(loadInitialProfilePic.fulfilled, (state, action) => {
        (state.isFetchProfilePicLoading = false),
          (state.isFetchProfilePicFailed = false),
          (state.isFetchProfilePicSuccess = true),
          (state.profileImage = action.payload);
      })
      .addCase(loadInitialProfilePic.rejected, (state, action) => {
        (state.isFetchProfilePicLoading = false),
          (state.isFetchProfilePicSuccess = false),
          (state.isFetchProfilePicFailed = true),
          (state.FetchProfilePicErrorContainer = {
            ...state.FetchProfilePicErrorContainer,
            ...action.payload
          });
      })
      .addCase(profilePicDelete.pending, state => {
        (state.isDeleteProfilePicLoading = true),
          (state.isDeleteProfilePicSuccess = false),
          (state.isDeleteProfilePicFailed = false),
          (state.DeleteProfilePicErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })
      .addCase(profilePicDelete.fulfilled, state => {
        (state.isDeleteProfilePicLoading = false),
          (state.isDeleteProfilePicFailed = false),
          (state.isDeleteProfilePicSuccess = true),
          (state.profileImage = IMG_PERSON_AVTAR_SMALL);
      })
      .addCase(profilePicDelete.rejected, (state, action) => {
        (state.isDeleteProfilePicLoading = false),
          (state.isDeleteProfilePicSuccess = false),
          (state.isDeleteProfilePicFailed = true),
          (state.DeleteProfilePicErrorContainer = {
            ...state.DeleteProfilePicErrorContainer,
            ...action.payload
          });
      })
      .addCase(dashboardIncentiveGraph.pending, state => {
        (state.isDashboardIncentiveGraphLoading = true),
          (state.isDashboardIncentiveGraphSuccess = false),
          (state.isDashboardIncentiveGraphFailed = false),
          (state.DashboardIncentiveGraphErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })
      .addCase(dashboardIncentiveGraph.fulfilled, (state, action) => {
        (state.isDashboardIncentiveGraphLoading = false),
          (state.isDashboardIncentiveGraphFailed = false),
          (state.isDashboardIncentiveGraphSuccess = true),
          (state.isDashboardIncentiveGraphData = action.payload);
      })
      .addCase(dashboardIncentiveGraph.rejected, (state, action) => {
        (state.isDashboardIncentiveGraphLoading = false),
          (state.isDashboardIncentiveGraphSuccess = false),
          (state.isDashboardIncentiveGraphFailed = true),
          (state.DashboardIncentiveGraphErrorContainer = {
            ...state.DeleteProfilePicErrorContainer,
            ...action.payload
          });
      })
      .addCase(clearLayoutState, state => {
        (state.isUploadProfilePicSuccess = false),
          (state.isUploadProfilePicFailed = false),
          (state.isFetchProfilePicSuccess = false),
          (state.isFetchProfilePicFailed = false),
          (state.isDeleteProfilePicSuccess = false),
          (state.isDeleteProfilePicFailed = false);
      });
  }
});

export default layoutSlice.reducer;
