import { Container, CssBaseline, ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import { lightTheme } from './config/theme';
import SplashScreen from './components/SplashScreen';
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate
} from 'react-router-dom';
import ProtectedRoutes from './components/ProtectedRoutes';
import NotFound from './pages/NotFound';
import Maintenance from './pages/Maintenance';
import Forbidden from './pages/Forbidden';
import Login from './pages/Login';
import InvestorStepper from './pages/InvestorStepper';
import { useSelector } from 'react-redux';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import AddLead from './containers/lead/AddLead';
import AddLeadOtpVerification from './containers/lead/AddLeadOtpVerification';
import LeadManagement from './pages/LeadManagement';
import BorrowerStepper from './pages/BorrowerStepper';
import SessionExpired from './components/SessionExpired';
import Profile from './pages/Profile';

function App() {
  const [showSplash, setShowSplash] = useState(
    Boolean(process.env.NODE_ENV === 'production')
  );
  const layout = useSelector(state => state.layout);
  const { isSessionExpired } = useSelector(state => state.session);

  useEffect(() => {
    const splashTimer = setTimeout(() => {
      setShowSplash(false);
    }, 1500);
    return () => {
      clearTimeout(splashTimer);
    };
  }, []);

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <Container disableGutters>
          {showSplash ? (
            <SplashScreen />
          ) : (
            <>
              <Router>
                {isSessionExpired && <SessionExpired />}
                <Routes>
                  <Route path="*" element={<NotFound />} />
                  <Route path="maintenance" element={<Maintenance />} />
                  <Route path="/forbidden" element={<Forbidden />} />
                  <Route path="/login" Component={Login} />
                  <Route exact path="/" element={<Navigate to="/login" />} />

                  <Route element={<ProtectedRoutes />}>
                    <Route path="/dashboard" Component={Dashboard} />

                    <Route
                      path="/add-investor"
                      element={
                        <Layout appbar={true} layout={layout} avtar={true}>
                          <InvestorStepper />
                        </Layout>
                      }
                    />
                    <Route
                      path="/homepage"
                      element={
                        <Layout
                          appbar={true}
                          layout={layout}
                          avtar={true}
                          drawer={false}
                        >
                          <Dashboard />
                        </Layout>
                      }
                    />
                    <Route
                      path="/add-lead-investor"
                      element={
                        <Layout
                          appbar={true}
                          layout={layout}
                          avtar={true}
                          drawer={false}
                        >
                          <AddLead type={'investor'} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/add-lead-borrower"
                      element={
                        <Layout
                          appbar={true}
                          layout={layout}
                          avtar={true}
                          drawer={false}
                        >
                          <AddLead type={'borrower'} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/add-lead-otp-investor"
                      element={
                        <Layout
                          appbar={true}
                          layout={layout}
                          avtar={true}
                          drawer={false}
                        >
                          <AddLeadOtpVerification
                            screen="add"
                            type={'investor'}
                          />
                        </Layout>
                      }
                    />
                    <Route
                      path="/add-lead-otp-borrower"
                      element={
                        <Layout
                          appbar={true}
                          layout={layout}
                          avtar={true}
                          drawer={false}
                        >
                          <AddLeadOtpVerification
                            screen="add"
                            type={'borrower'}
                          />
                        </Layout>
                      }
                    />
                    <Route
                      path="/edit-lead-otp-investor"
                      element={
                        <Layout
                          appbar={true}
                          layout={layout}
                          avtar={true}
                          drawer={false}
                        >
                          <AddLeadOtpVerification
                            screen="edit"
                            type={'investor'}
                          />
                        </Layout>
                      }
                    />
                    <Route
                      path="/edit-lead-otp-borrower"
                      element={
                        <Layout
                          appbar={true}
                          layout={layout}
                          avtar={true}
                          drawer={false}
                        >
                          <AddLeadOtpVerification
                            screen="edit"
                            type={'borrower'}
                          />
                        </Layout>
                      }
                    />
                    <Route
                      path="/add-borrower"
                      element={
                        <Layout
                          appbar={true}
                          layout={layout}
                          avtar={true}
                          drawer={false}
                        >
                          <BorrowerStepper />
                        </Layout>
                      }
                    />

                    <Route
                      path="/lead-management-investor"
                      element={
                        <Layout
                          appbar={true}
                          layout={layout}
                          avtar={true}
                          drawer={false}
                        >
                          <LeadManagement type="investor" />
                        </Layout>
                      }
                    />
                    <Route
                      path="/profile"
                      element={
                        <Layout
                          appbar={true}
                          layout={layout}
                          avtar={true}
                          drawer={false}
                        >
                          <Profile />
                        </Layout>
                      }
                    />

                    <Route
                      path="/lead-management-borrower"
                      element={
                        <Layout
                          appbar={true}
                          layout={layout}
                          avtar={true}
                          drawer={false}
                        >
                          <LeadManagement type="borrower" />
                        </Layout>
                      }
                    />
                  </Route>
                </Routes>
              </Router>
            </>
          )}
        </Container>
      </ThemeProvider>
    </>
  );
}

export default App;
