import { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Button,
  Box,
  Tooltip,
  Typography,
  Skeleton
} from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import {
  DOC_TYPE_BANK_STATEMENT_OCR,
  IMAGE_SIZE_LIMIT_MESSAGE,
  UPLOAD_DOCUMENT_SELECT_IMAGE_MESSAGE,
  UPLOAD_DOCUMENT_SELECT_PDF_MESSAGE,
  UploadDocumentAllowedTypes,
  UploadPdfAllowedTypes
} from '../utils/constant';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import SnackBarBox from './SnackBarBox';
import { handleError, isUploadImageExtensionAllowed } from '../utils/utils';
import {
  investorDownloadDocument,
  viewImage
} from '../redux/reducers/documentSlice';
import { useDispatch, useSelector } from 'react-redux';
import ImageCropper from './ImageCropper';
import { colorLightYellow, colorPrimaryBlue } from '../config/theme';
import { IMG_IMAGE_PREVIEW, IMG_PDF_PREVIEW } from '../utils/imageUrls';

const uploadButton = {
  backgroundColor: colorLightYellow,
  border: '1px dashed #F5B103',
  color: colorPrimaryBlue
};

const UploadAndViewImage = ({
  onImageUpload,
  buttonName = 'Upload Image',
  docType = null,
  handleDelete
}) => {
  const dispatch = useDispatch();

  const { documentDetails } = useSelector(state => state.documents);

  const [imageUploadError, setImageUploadError] = useState({
    error: false,
    errorMessage: ''
  });
  const [actualImage, setActualImage] = useState(null);

  const [isLoadingSavedImage, setIsLoadingSavedImage] = useState(false);

  const [isImage, setIsImage] = useState(null);

  const [currentUploadedFile, setCurrentUploadedFile] = useState(null);

  const [uploadedImage, setUploadedImage] = useState(null);

  const [uploadedFileName, setUploadedFileName] = useState('');

  useEffect(() => {
    const getImage = async () => {
      setIsLoadingSavedImage(true);

      const uploadedLink = documentDetails[docType];

      if (uploadedLink) {
        if (
          uploadedLink?.contentType &&
          String(uploadedLink?.contentType).includes('pdf')
        ) {
          setIsImage(false);
        } else {
          setIsImage(true);
        }
        setCurrentUploadedFile(uploadedLink);
        const img = await viewImage(uploadedLink?.s3ObjectKey);
        setActualImage(img);
      } else {
        handleClearImage();
      }

      setIsLoadingSavedImage(false);
    };
    getImage();
  }, [documentDetails, docType]);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (imageUploadError.error) {
      const timeoutId = setTimeout(() => {
        setImageUploadError({
          error: false,
          errorMessage: ''
        });
        handleClearImage();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [imageUploadError]);

  const handleImageUpload = event => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = UploadDocumentAllowedTypes;
      const maxSizeMB = process.env.REACT_APP_MULTIPART_FILE_SIZE_LIMIT;
      const fileName = file ? file?.name : 'NA';

      if (allowedTypes.includes(file.type)) {
        if (isUploadImageExtensionAllowed(fileName)) {
          if (file.size / 1024 / 1024 <= maxSizeMB) {
            setUploadedFileName(fileName);
            const reader = new FileReader();
            if (String(fileName).includes('pdf')) {
              onImageUpload({ file: file, docType: docType });
            } else {
              reader.onload = e => {
                setUploadedImage(e.target.result);
              };
              reader.readAsDataURL(file);
            }
          } else {
            setImageUploadError({
              error: true,
              errorMessage: `File size exceeds the maximum allowed (${maxSizeMB} MB).`
            });
          }
        } else {
          setImageUploadError({
            error: true,
            errorMessage: UPLOAD_DOCUMENT_SELECT_IMAGE_MESSAGE
          });
        }
      } else {
        setImageUploadError({
          error: true,
          errorMessage: UPLOAD_DOCUMENT_SELECT_IMAGE_MESSAGE
        });
      }
    }
  };

  const handleClearImage = () => {
    setUploadedImage(null);
    setCurrentUploadedFile(null);
    setIsImage(null);
    setActualImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const deleteActualImage = () => {
    handleDelete(currentUploadedFile?.fileName, docType);
    handleClearImage();
  };

  const handleDownloadImage = () => {
    dispatch(
      investorDownloadDocument({
        fileName: currentUploadedFile?.fileName,
        fileUrl: currentUploadedFile?.s3ObjectKey
      })
    );
  };

  const handleFileUpload = file => {
    onImageUpload({ file: file, docType: docType });
    setUploadedImage(null);
  };

  return (
    <>
      {isLoadingSavedImage ? (
        <>
          <Skeleton variant="sqaure" height={100} width={100} />
        </>
      ) : (
        <Grid item xs={12} md={12}>
          <div style={{ position: 'relative' }}>
            {uploadedImage && (
              <ImageCropper
                key={docType}
                uploadedFileName={uploadedFileName}
                incomingFile={uploadedImage}
                handleFileUpload={handleFileUpload}
                handleFileCancel={handleClearImage}
              />
            )}
            {actualImage ? (
              <>
                {isImage && isImage ? (
                  <Box>
                    <Tooltip
                      placement="top"
                      title={`Download ${currentUploadedFile?.fileName}`}
                    >
                      <img
                        className="imgbox"
                        src={actualImage}
                        alt="Actual Image"
                        width={110}
                        height={90}
                        onClick={handleDownloadImage}
                      />
                    </Tooltip>
                  </Box>
                ) : (
                  <Box>
                    <Tooltip
                      placement="top"
                      title={`Download ${currentUploadedFile?.fileName}`}
                    >
                      <img
                        className="imgbox"
                        src={IMG_PDF_PREVIEW}
                        alt="Uploaded PDF"
                        width={110}
                        height={90}
                        onClick={handleDownloadImage}
                      />
                    </Tooltip>
                  </Box>
                )}
              </>
            ) : (
              <img width={110} src={IMG_IMAGE_PREVIEW} alt="Image Preview" />
            )}
          </div>
          {actualImage ? (
            <Tooltip title={`Delete ${currentUploadedFile?.fileName}`}>
              <Button
                color="error"
                size="small"
                onClick={deleteActualImage}
                variant="outlined"
                sx={{ marginTop: '10px' }}
              >
                <DeleteIcon /> Delete
              </Button>
            </Tooltip>
          ) : (
            <>
              <Tooltip
                title={
                  docType === DOC_TYPE_BANK_STATEMENT_OCR
                    ? UPLOAD_DOCUMENT_SELECT_PDF_MESSAGE
                    : UPLOAD_DOCUMENT_SELECT_IMAGE_MESSAGE
                }
                placement="top"
              >
                <Button
                  component="label"
                  startIcon={<DriveFolderUploadIcon />}
                  size="small"
                  sx={uploadButton}
                >
                  {buttonName}
                  <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    accept={
                      docType === DOC_TYPE_BANK_STATEMENT_OCR
                        ? UploadPdfAllowedTypes.join(',')
                        : UploadDocumentAllowedTypes.join(',')
                    }
                    onChange={handleImageUpload}
                  />
                </Button>
              </Tooltip>
              <Typography
                align="center"
                marginLeft="5px"
                variant="body2"
                sx={{ fontSize: '11px' }}
              >
                {IMAGE_SIZE_LIMIT_MESSAGE}
              </Typography>
            </>
          )}
          {imageUploadError.error && (
            <SnackBarBox
              type="error"
              message={handleError(imageUploadError.errorMessage)}
            />
          )}
        </Grid>
      )}
    </>
  );
};

UploadAndViewImage.propTypes = {
  onImageUpload: PropTypes.func,
  buttonName: PropTypes.string,
  handleDelete: PropTypes.func,
  docType: PropTypes.string
};
export default UploadAndViewImage;
